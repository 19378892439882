<template>
  <v-row no-gutters>
    <div class="mt-n2 mr-2">
      <checkbox
        :fieldAttrInput="{}"
        :fieldAttributes="{name:'Save in Variable'}"
        v-model="checked"
        :field="field"
      ></checkbox>
    </div>

    <primaryTextfield
      v-if="typeof result.valueLine.saveInVariable!='undefined'"
      :value="value"
      v-on="$listeners"
      :fieldAttrInput="{}"
      label="Variable"
    ></primaryTextfield>
  </v-row>
</template>
<script>
import checkbox from "@/commonComponents/checkbox.vue";
import primaryTextfield from "@/commonComponents/primaryTextfield.vue";
export default {
  props: ["result", "field", "fieldAttributes","value"],
  components: { primaryTextfield, checkbox },
  computed: {
    checked: {
      get() {
        let checked = false;
        if (typeof this.value != "undefined") {
          checked = true;
        }
        return checked;
      },
      set(newVal) {
        if (newVal == 1) {
          this.$emit("input", "");
        } else {
          this.$emit("delete-input");
        }
      },
    },
  
  },

  methods: {
    toggleSave() {
      if (typeof this.value == "undefined") {
        this.$emit("input", "");
      } else {
        this.$emit("delete-input");
      }
    },
  },
};
</script>